<template>
  <CyMenu
    :close-on-content-click="false"
    offset-y>
    <template #activator="{ on, attrs }">
      <div>
        <CyButton
          class="mx-4"
          icon="add"
          variant="tertiary"
          theme="grey"
          v-bind="attrs"
          v-on="on">
          {{ $t('addFilter') }}
        </CyButton>
      </div>
    </template>
    <div
      class="filter-tree-menu"
      data-cy="filter-tree-menu">
      <div
        v-if="!isTopLevel"
        class="category-header"
        data-cy="category-header">
        <CyButton
          data-cy="back-button"
          variant="tertiary"
          theme="grey"
          icon-only
          icon="chevron_left"
          @click="backBtnHandler"/>
        {{ levelDepth === 1 ? $t(focusedCategory) : focusedCategory }}
      </div>
      <ul
        class="filter-list pb-2"
        data-cy="filter-list">
        <v-text-field
          v-model="search"
          data-cy="search-bar"
          class="search-bar pa-2"
          single-line
          hide-details
          filled>
          <template #label>
            <v-icon>
              search
            </v-icon>
            {{ $t(searchBarLabel) }}...
          </template>
        </v-text-field>
        <li
          v-for="[key, itemValue] in focusedSubTreeWithSearch"
          :key="key"
          :aria-label="getItemLabel(key, itemValue)"
          class="filter-list__item"
          @click="itemClickHandler(key)">
          <v-checkbox
            v-if="isInFilterDepth"
            color="secondary"
            class="mt-0 pt-0"
            :input-value="getCheckboxValue(key)"
            hide-details>
            <template #label>
              <CyTooltip
                open-delay="250"
                top>
                <template #activator="{ on }">
                  <span
                    class="filter-checkbox__label"
                    v-on="on">
                    {{ getItemLabel(key, itemValue) }}
                  </span>
                </template>
                {{ getItemLabel(key, itemValue) }}
              </CyTooltip>
            </template>
          </v-checkbox>
          <span v-else>
            {{ getItemLabel(key, itemValue) }}
          </span>
        </li>
        <div
          v-if="_.isEmpty(focusedSubTreeWithSearch)"
          class="filter-list--empty px-4 py-1">
          {{ $t('noItemsFound') }}
        </div>
      </ul>
      <div
        v-if="isInFilterDepth"
        data-cy="actions-footer"
        :class="['actions-footer d-flex pa-2', anyFiltersSelectedInCurrentCategory ? 'justify-space-between' : 'justify-end']">
        <CyButton
          v-if="anyFiltersSelectedInCurrentCategory"
          variant="tertiary"
          theme="grey"
          @click="clearCategoryFilters">
          {{ $t('clearFilters') }}
        </CyButton>
        <CyButton
          data-cy="apply-button"
          @click="applyFilters">
          {{ $t('applyFilters') }}
        </CyButton>
      </div>
    </div>
  </CyMenu>
</template>

<script>
const defaultFilterTreeFormatter = (filterTree) => {
  const filterTreeNoEmpty = _.omitBy(filterTree, _.isEmpty)
  return _.mapValues(filterTreeNoEmpty, (valueArray) => _.fromPairs(_.map(valueArray, (value) => [value, null])))
}

export default {
  name: 'CyFilterTree',
  props: {
    filterTree: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Array,
      default: () => [],
    },
    filterTreeFormatter: {
      type: Function,
      default: defaultFilterTreeFormatter,
    },
  },
  data: () => ({
    focusedTreePath: '',
    selectedFilters: [],
    search: '',
  }),
  computed: {
    formattedFilterTree () {
      return this.filterTreeFormatter(this.filterTree)
    },
    isTopLevel () {
      return !this.focusedTreePath
    },
    focusedSubTree () {
      if (this.isTopLevel) return this.formattedFilterTree
      return _.get(this.formattedFilterTree, this.focusedTreePath, {})
    },
    focusedCategory () {
      if (this.isTopLevel) return null
      return _.last(this.focusedTreePath.split('.'))
    },
    levelDepth () {
      const pathArray = this.isTopLevel ? [] : this.focusedTreePath.split('.')
      return pathArray.length
    },
    isInFilterDepth () {
      return _.some(_.values(this.focusedSubTree), (item) => item === null || typeof item === 'number')
    },
    anyFiltersSelectedInCurrentCategory () {
      return this.isInFilterDepth && _.some(this.selectedFilters, (selection) => selection.startsWith(this.focusedTreePath))
    },
    focusedSubTreeWithSearch () {
      return _.toPairs(this.focusedSubTree).filter(([key]) => key.toLowerCase().includes(this.search.toLowerCase()))
    },
    searchBarLabel () {
      if (this.isTopLevel) return 'filterBy'
      if (this.isInFilterDepth) return 'searchForValues'
      return 'forms.fieldSearch'
    },
  },
  watch: {
    value (newValue) {
      this.selectedFilters = newValue
    },
  },
  mounted () {
    this.selectedFilters = this.value
  },
  methods: {
    focusSubTree (itemKey) {
      this.search = ''
      const pathArray = this.isTopLevel ? [] : this.focusedTreePath.split('.')
      this.focusedTreePath = [...pathArray, itemKey].join('.')
    },
    getItemLabel (key, value) {
      if (this.isTopLevel) return this.$t(key)
      if (this.isInFilterDepth) {
        if (value !== null) return `${key} (${value})`
        return `${key}`
      }
      return key
    },
    backBtnHandler () {
      this.search = ''
      const pathArray = this.focusedTreePath.split('.')
      this.focusedTreePath = pathArray.slice(0, -1).join('.')
    },
    toggleFilter (key) {
      const filterKey = `${this.focusedTreePath}.${key}`
      this.selectedFilters = _.xor(this.selectedFilters, [filterKey])
    },
    itemClickHandler (key) {
      this.isInFilterDepth ? this.toggleFilter(key) : this.focusSubTree(key)
    },
    getCheckboxValue (key) {
      const filterKey = `${this.focusedTreePath}.${key}`
      return _.includes(this.selectedFilters, filterKey)
    },
    clearCategoryFilters () {
      this.selectedFilters = this.selectedFilters.filter((filter) => !filter.startsWith(this.focusedTreePath))
    },
    applyFilters () {
      this.$emit('input', this.selectedFilters)
    },
  },
  i18n: {
    messages: {
      en: {
        addFilter: 'Add filter',
        applyFilters: 'Apply filters',
        clearFilters: 'Clear filters',
        environments: '@:Environment',
        filterBy: 'Filter by',
        labels: '@:untranslated.label',
        modules: 'Module',
        noItemsFound: 'No items found',
        projects: '@:Project',
        providers: '@:provider',
        searchForValues: 'Search for values',
        tags: '@:untranslated.tags',
        types: '@:forms.type',
      },
      es: {
        addFilter: 'Añadir filtro',
        applyFilters: 'Aplicar filtros',
        clearFilters: 'Borrar filtros',
        environments: '@:Environment',
        filterBy: 'Filtrado por',
        labels: '@:untranslated.label',
        modules: 'Módulo',
        noItemsFound: 'No artículos encontrados',
        projects: '@:Project',
        providers: '@:provider',
        searchForValues: 'Buscar valores',
        tags: '@:untranslated.tags',
        types: '@:forms.type',
      },
      fr: {
        addFilter: 'Ajouter un filtre',
        applyFilters: 'Appliquer les filtres',
        clearFilters: 'Effacer les filtres',
        environments: '@:Environment',
        filterBy: 'Filtrer par',
        labels: '@:untranslated.label',
        modules: 'Module',
        noItemsFound: 'Aucun filtre correspondant',
        projects: '@:Project',
        providers: '@:provider',
        searchForValues: 'Rechercher des valeurs',
        tags: '@:untranslated.tags',
        types: '@:forms.type',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.category-header {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid cy-get-color("primary", "light-5");
  color: cy-get-color("primary");
  font-weight: $font-weight-bolder;
}

.filter-tree-menu {
  width: 264px;
}

.filter-list {
  padding-left: 0;
  list-style-type: none;

  &__item {
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
      background-color: cy-get-color("grey", "light-3");
    }
  }
}

.filter-checkbox {
  &__label {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.actions-footer {
  border-top: 1px solid cy-get-color("primary", "light-5");
}

::v-deep .search-bar.v-input {
  .v-input__control {
    .v-input__slot {
      min-height: 32px;
      max-height: 32px;
      border-radius: 2px;

      &::before {
        border: none;
      }

      &::after {
        display: none;
      }
    }

    label {
      top: 6px;
      color: cy-get-color("primary", "light-3");

      i {
        color: cy-get-color("primary", "light-3");
      }
    }
  }
}
</style>
